<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="900px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="规则名称:" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="预警仓库" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            clearable
            style="width: 140px"
            placeholder="预警仓库"
          >
            <el-option
              v-for="(i, idx) in ckList"
              :key="idx"
              :value="i.id"
              :label="i.depot_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="margin-bottom: 15px; font-size: 16px">预警商品:</div>
        <el-table stripe :data="form.goods" @selection-change="selectTable">
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          >
            <template #default="{ row }">
              <div v-if="item.label == '商品名称'">
                <goods-search
                  :f-key="row.goods_name"
                  @select-goods-all="goodsSelect($event, row)"
                  @add-rows="addRows"
                ></goods-search>
              </div>
              <div v-else-if="item.label == '单位'">
                <el-select v-model="row.unit_type" clearable placeholder="单位">
                  <el-option
                    v-for="(i, idx) in row.arr_unit"
                    :key="idx"
                    :value="i.unit_type"
                    :label="i.unit_name"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '缺货预警值'">
                <el-input v-model="row.scarce_value" type="number"></el-input>
              </div>
              <div v-else-if="item.label == '积压预警值'">
                <el-input
                  v-model="row.overstock_value"
                  type="number"
                ></el-input>
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            min-width="110px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <!-- 批量设置 -->
      <el-form
        ref="form2"
        :model="form2"
        inline
        label-position="right"
        label-width="100px"
      >
        <div style="margin: 15px 0; font-size: 16px">批量设置:</div>
        <el-form-item label="预警单位:" prop="unit_type">
          <el-select
            v-model="form2.unit_type"
            clearable
            style="width: 120px"
            placeholder="预警单位"
          >
            <el-option
              v-for="(i, idx) in unit_type"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺货预警值:" prop="a">
          <el-input
            v-model="form2.a"
            type="number"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="积压预警值:" prop="b">
          <el-input
            v-model="form2.b"
            type="number"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="setBatch">设置</el-button>
        </el-form-item>
      </el-form>
      <!-- 统一设置 -->
      <el-form
        ref="form3"
        :model="form3"
        inline
        label-position="right"
        label-width="100px"
      >
        <div style="margin: 15px 0; font-size: 16px">
          <el-checkbox v-model="checked">
            统一设置:将该仓库全部商品设置为
          </el-checkbox>
        </div>
        <el-form-item label="预警单位:" prop="unit_type">
          <el-select
            v-model="form3.unit_type"
            clearable
            style="width: 120px"
            placeholder="预警单位"
          >
            <el-option
              v-for="(i, idx) in unit_type"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺货预警值:" prop="a">
          <el-input
            v-model="form3.a"
            type="number"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="积压预警值:" prop="b">
          <el-input
            v-model="form3.b"
            type="number"
            style="width: 120px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保 存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { depotList } from '@/api/depotManagement'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { rulesEdit, rulesDetail } from '@/api/warning'
  export default {
    name: '',
    components: { GoodsSearch },
    data() {
      return {
        showDialog: false,
        loading: false,
        ckList: [],
        isAdd: true,
        title: '编辑规则',
        unit_type: [
          {
            id: 1,
            name: '小单位',
          },
          {
            id: 2,
            name: '中单位',
          },
          {
            id: 3,
            name: '大单位',
          },
        ],
        form: {
          depot_id: '', //创库ID
          title: '', //活动名称
          goods: [], //规则商品
          id: '', //修改时必填
        },
        form2: {
          unit_type: '',
          a: '',
          b: '',
        },
        checked: false,
        form3: {
          unit_type: '',
          a: '',
          b: '',
        },
        checkList: ['商品名称', '单位', '单位换算', '缺货预警值', '积压预警值'],
        columns: [
          {
            order: 1,
            label: '商品名称',
            prop: 'goods_name',
            width: '220px',
          },
          {
            order: 2,
            label: '单位',
            prop: 'unit_type',
            width: '',
          },
          {
            order: 3,
            label: '单位换算',
            prop: 'goods_cv',
            width: '',
          },
          {
            order: 4,
            label: '缺货预警值',
            prop: 'scarce_value',
            width: '',
          },
          {
            order: 5,
            label: '积压预警值',
            prop: 'overstock_value',
            width: '',
          },
        ],
        selectRows: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          depotList().then((res) => {
            this.ckList = res.data
          })
          if (!this.isAdd) {
            this.fetchData().then((res) => {
              this.addRow()
            })
          } else {
            this.addRow()
          }
        } else {
          this.form = {
            depot_id: '', //创库ID
            title: '', //活动名称
            goods: '', //规则商品
            id: '', //修改时必填
          }
          this.form.goods = []
          this.form3 = {
            unit_type: '',
            a: '',
            b: '',
          }
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await rulesDetail({ id: this.form.id })
        this.form = data
        this.loading = false
      },
      goodsSelect(val, row) {
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        row.unit_type = ''
        row.arr_unit = val.arr_unit
        row.goods_cv = val.unit_cv
        row.scarce_value = ''
        row.overstock_value = ''
        if (this.form.goods[this.form.goods.length - 1].goods_id != '') {
          this.addRow()
        }
      },
      addRow() {
        this.form.goods.push({
          goods_id: '',
          goods_name: '',
          unit_type: '',
          goods_cv: '',
          arr_unit: [],
          scarce_value: '',
          overstock_value: '',
        })
      },
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            this.form.goods.splice(this.form.goods.length - 1, 0, {
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              unit_type: '',
              goods_cv: item.unit_cv,
              arr_unit: item.arr_unit,
              scarce_value: '',
              overstock_value: '',
            })
          })
          this.$message.success('添加成功')
        }
      },
      // 表格操作
      handleCopyRow(index, row, listNum = 1) {
        let list = eval('this.list' + listNum)
        list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row) {
        if (row.goods_id == '') {
          this.$message.error('不能删除此行')
          return false
        }
        if (this.form.goods.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.form.goods.splice(index, 1)
        }
      },
      selectTable(val) {
        this.selectRows = val
      },
      setBatch() {
        if (this.selectRows.length == 0) {
          this.$message.error('请勾选商品')
        } else {
          this.selectRows.forEach((item) => {
            // item.unit_type = form2.unit_type
            if (item.goods_id) {
              if (this.form2.unit_type !== '') {
                item.unit_type = this.form2.unit_type
              }
              if (this.form2.a !== '') {
                item.scarce_value = this.form2.a
              }
              if (this.form2.b !== '') {
                item.overstock_value = this.form2.b
              }
            }
          })
        }
      },
      close() {
        this.showDialog = false
      },
      handleSave() {
        let goods_data = JSON.parse(JSON.stringify(this.form.goods))
        goods_data.forEach((item, index) => {
          if (item.goods_id == '') {
            goods_data.splice(index, 1)
          }
          if (this.checked) {
            // 统一设置
            goods_data.forEach((item) => {
              // item.unit_type = form2.unit_type
              if (item.goods_id) {
                if (this.form3.unit_type !== '') {
                  item.unit_type = this.form3.unit_type
                }
                if (this.form3.a !== '') {
                  item.scarce_value = this.form3.a
                }
                if (this.form3.b !== '') {
                  item.overstock_value = this.form3.b
                }
              }
            })
          }
        })
        let title = this.form.title
        let depot_id = this.form.depot_id
        if (this.isAdd) {
          var end = {
            goods_data: JSON.stringify(goods_data),
            title: title,
            depot_id: depot_id,
          }
        } else {
          var end = {
            goods_data: JSON.stringify(goods_data),
            title: title,
            depot_id: depot_id,
            id: this.form.id,
          }
        }
        console.log(end)
        rulesEdit(end).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
