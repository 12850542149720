<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="depot_id">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="仓库"
        >
          <el-option
            v-for="(i, idx) in ckList"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="class_id">
        <class-select
          ref="classSelect"
          :popper-class="'select-idx'"
          @class-select-change="chooseClass"
        ></class-select>
      </el-form-item>
      <el-form-item label="" prop="brand_id">
        <brand-select
          ref="brandSelect"
          style="width: 140px"
          @brand-select-change="setBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="goods_name">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="goodsSelect"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleRules">预警规则</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handlerExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>

    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <rules ref="rules"></rules>
  </div>
</template>
<script>
  import _ from 'lodash'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import Rules from './components/rules'
  import { getList } from '@/api/warning'
  import { depotList } from '@/api/depotManagement'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'Backlog',
    components: { GoodsSearch, ClassSelect, BrandSelect, Rules },
    data() {
      return {
        loading: false,
        ckList: [],
        form: {
          pageNo: 1, //当前页
          pageSize: 10, //页条数
          depot_id: '', //仓库ID
          keyword: '', //商品名称、简拼
          sort: '', //排序字段(depot_id) 默认为depot_id
          order: '', //排序类型(asc|desc) 默认asc
          brand_id: '', //品牌
          class_id: '', //品类
        },
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '仓库',
          '品牌',
          '商品名称',
          '规格',
          '近30天日均出库',
          '积压预警值',
          '实时库存',
        ],
        columns: [
          {
            order: 0,
            label: '仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 1,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 4,
            label: '近30天日均出库',
            prop: 'last_month_sale',
            width: '',
          },
          {
            order: 5,
            label: '积压预警值',
            prop: 'min_goods_value_text',
            width: '',
          },
          {
            order: 6,
            label: '实时库存',
            prop: 'balance_count_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
      depotList().then((res) => {
        this.ckList = res.data
      })
    },
    mounted() {},
    methods: {
      handlerExport() {
        downloadFile(
          '/customerAdmin/warning/overstock-list-export',
          '积压.xlsx',
          this.form
        )
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      chooseClass(val) {
        this.form.class_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      setBrand(val) {
        this.form.brand_id = val
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.form.goods_id = ''
        this.$refs.goodsSearch.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.classSelect.resetForm()
      },
      handleRules() {
        this.$refs.rules.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
